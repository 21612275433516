export default function Footer() {
  return (
    <footer className="py-4 bg-gray-800 text-white text-center">
      <div className="container mx-auto">
        <p>&copy; {new Date().getFullYear()} Bloom. All rights reserved.</p>
        <div className="mt-2">
          <a href="/privacy" className="mx-2">
            Privacy Policy
          </a>
          <a href="/terms" className="mx-2">
            Terms of Service
          </a>
        </div>
      </div>
    </footer>
  )
}
