import { UploadIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import { useId } from 'react'
import { useRecoilValue } from 'recoil'
import { FieldValues, Path, UseFormReturn } from 'react-hook-form'

import { uploadGenericFile } from '@/repository'
import { sessionState } from '@/store'
import FileManager from '@/components/FileManager'
import { FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Button } from '@/components/ui/button'

interface Props<TFieldValues extends FieldValues> {
  className?: string
  form: UseFormReturn<TFieldValues>
  id?: string
  label?: string
  name: Path<TFieldValues>
}

const ImageField = <TFieldValues extends FieldValues = FieldValues>({
  className,
  form,
  id: nativeId,
  label,
  name,
}: Props<TFieldValues>) => {
  const session = useRecoilValue(sessionState)
  const autoId = useId()
  const id = nativeId || autoId

  const queryClient = useQueryClient()

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field: { onChange, value, ...field } }) => (
        <FormItem className={className}>
          {label && <FormLabel className="mt-0.5">{label}</FormLabel>}

          <div className="flex gap-2 border p-2 bg-white rounded-md">
            <div className="size-20 flex-shrink-0 rounded-sm overflow-hidden">
              {value ? (
                <img
                  src={value}
                  alt={`${name} logo preview`}
                  className="size-full object-cover"
                />
              ) : (
                <div className="size-full bg-secondary" />
              )}
            </div>

            <div className="flex-grow flex flex-col items-stretch gap-2">
              <Button
                asChild
                variant="outline"
                icon={UploadIcon}
                className="cursor-pointer"
              >
                <label htmlFor={id}>Upload new image</label>
              </Button>

              <input
                id={id}
                accept="image/png, image/jpeg"
                type="file"
                {...field}
                onChange={async (ev) => {
                  const file = ev.target.files?.[0]

                  if (file) {
                    const response = await uploadGenericFile({
                      blob: file,
                      name: file.name,
                      userId: session!.user.id,
                    })

                    onChange({ target: { value: response.url } })

                    // Invalidate the files query to update the files manager
                    await queryClient.invalidateQueries({
                      queryKey: ['files'],
                    })
                    onChange('')
                  }
                }}
                className="hidden"
              />

              <FileManager onSelect={(url) => onChange(url)} />
            </div>
          </div>
        </FormItem>
      )}
    />
  )
}

export default ImageField
