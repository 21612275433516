import { cn } from '@/lib/utils'

export default function SkeletonDocument({
  loading = true,
}: {
  loading?: boolean
}) {
  const animation = loading && 'animate-pulse'

  return (
    <div className="p-8 space-y-2">
      <div className={cn('h-4 bg-zinc-200 w-3/4 rounded-sm', animation)} />
      <div className={cn('h-3 bg-zinc-200 w-1/2 rounded-sm', animation)} />
      <div className="h-4" />
      <div className={cn('h-2 bg-zinc-200 rounded-sm', animation)} />
      <div className={cn('h-2 bg-zinc-200 rounded-sm', animation)} />
      <div className={cn('h-2 bg-zinc-200 rounded-sm', animation)} />
      <div className={cn('h-2 bg-zinc-200 rounded-sm', animation)} />
      <div className={cn('h-2 bg-zinc-200 rounded-sm', animation)} />
      <div className={cn('h-2 bg-zinc-200 rounded-sm', animation)} />
    </div>
  )
}
