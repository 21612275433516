import { PlusIcon } from '@radix-ui/react-icons'
import {
  useFieldArray,
  type UseFieldArrayRemove,
  useFormContext,
  type UseFormReturn,
} from 'react-hook-form'

import InputField from '@/components/form/InputField'
import { Button } from '@/components/ui/button'
import type { Resume } from '@/schema/ResumeSchema'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import RemoveItemButton from '../RemoveItemButton'
import ImageField from '@/components/form/ImageField'
import NoTitleAccordion from '../NoTitleAccordion'
import ArrayField from '../ArrayField'
import TextareaField from '@/components/form/TextareaField'

interface HighlightProps {
  form: UseFormReturn<Resume>
  index: number
}

interface ProjectFieldProps {
  index: number
  remove: UseFieldArrayRemove
}

const HighlightsField = ({ index, form }: HighlightProps) => {
  const { fields, append, remove } = useFieldArray({
    name: `projects.${index}.highlights`,
  })

  return (
    <div className="md:col-span-2">
      <header className="flex items-center justify-between mb-2">
        <h3>Highlights</h3>

        <Tooltip>
          <TooltipTrigger asChild>
            <Button size="icon" variant="ghost" onClick={() => append('')}>
              <PlusIcon />
            </Button>
          </TooltipTrigger>

          <TooltipContent>Add Highlight</TooltipContent>
        </Tooltip>
      </header>

      <div className="space-y-2">
        {fields.map((field, hIndex) => (
          <div key={field.id} className="relative flex items-start gap-2">
            <TextareaField
              form={form}
              name={`projects.${index}.highlights.${hIndex}`}
              className="flex-grow"
            />

            <RemoveItemButton onClick={() => remove(hIndex)} />
          </div>
        ))}
      </div>
    </div>
  )
}

const ProjectField = ({ index, remove }: ProjectFieldProps) => {
  const form = useFormContext<Resume>()

  const triggerTitle = form.watch(`projects.${index}.name`) || (
    <NoTitleAccordion>New Project</NoTitleAccordion>
  )

  return (
    <AccordionItem value={`projects.${index}`} className="border-none">
      <AccordionTrigger className="group gap-6 bg-white py-2 px-4 rounded-lg border">
        <header className="flex flex-grow items-center justify-between gap-2 font-bold">
          {triggerTitle}
          <RemoveItemButton
            onClick={() => remove(index)}
            className="opacity-0 group-hover:opacity-100 transition-opacity"
          />
        </header>
      </AccordionTrigger>

      <AccordionContent className="mt-4 mb-8 p-0">
        <div className="group relative grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField
            form={form}
            name={`projects.${index}.name`}
            label="Name"
          />

          <InputField
            form={form}
            name={`projects.${index}.role`}
            label="Role"
            placeholder="Creator, Developer, etc."
          />

          <InputField
            form={form}
            name={`projects.${index}.entity`}
            label="Entity"
            placeholder="Company, Organization, etc."
          />

          <InputField
            form={form}
            name={`projects.${index}.type`}
            label="Type"
            placeholder="Open Source, Personal, SaaS, etc."
          />

          <ImageField
            form={form}
            name={`projects.${index}.logo`}
            label="Logo"
          />

          <InputField
            form={form}
            name={`projects.${index}.url`}
            label="Website"
            placeholder="https://example.com"
            type="url"
          />

          <InputField
            form={form}
            name={`projects.${index}.summary`}
            label="Summary"
            className="md:col-span-2"
          />

          <InputField
            form={form}
            name={`projects.${index}.startDate`}
            label="Start Date"
            placeholder="Jan 2023"
          />

          <InputField
            form={form}
            name={`projects.${index}.endDate`}
            label="End Date"
            placeholder="May 2024"
          />

          <HighlightsField index={index} form={form} />
        </div>
      </AccordionContent>
    </AccordionItem>
  )
}

export default function ProjectsSection() {
  const fieldArray = useFieldArray<Resume>({
    name: 'projects',
  })

  const { fields, append, remove } = fieldArray

  return (
    <ArrayField
      addLabel="Project"
      fieldArray={fieldArray}
      onAddClick={() => {
        append({ name: '' })
        return `projects.${fields.length}`
      }}
    >
      {fields.map((field, index) => (
        <ProjectField key={field.id} index={index} remove={remove} />
      ))}
    </ArrayField>
  )
}
