export interface Testimonial {
  quote: string
  author: string
}

export default function Testimonials() {
  const testimonials: Testimonial[] = [
    {
      quote:
        'Bloom helped me land my dream job! The AI tailoring was incredibly effective.',
      author: 'Suzana Z., Procurement Manager',
    },
    {
      quote:
        'I used to dread writing resumes. Bloom made the process effortless.',
      author: 'Federico N., Supply Chain Manager',
    },
    {
      quote: 'I was able to get a job within a week of using Bloom.',
      author: 'Nick G., Cloud Architect',
    },
    {
      quote:
        'The AI was able to identify my skills and experience better than I could.',
      author: 'Sebastien S., Software Engineer',
    },
  ]

  return (
    <section className="py-24 text-center">
      <div className="container mx-auto">
        <h2 className="text-4xl font-serif text-gray-800 mb-12">
          What Our Users Are Saying
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-screen-lg mx-auto">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.author}
              className="p-6 rounded-2xl bg-[#f4f2ef]"
            >
              <p className="text-lg italic text-gray-700 mb-4">
                " {testimonial.quote} "
              </p>
              <p className="text-sm font-semibold text-gray-600">
                — {testimonial.author}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
