import { motion, type MotionValue, useScroll, useTransform } from 'motion/react'
import { useRef } from 'react'

const keywords = [
  'React',
  'Node.js',
  'TypeScript',
  'GraphQL',
  'AWS',
  'Docker',
  'Scheduling',
  'Team Management',
  'Agile',
  'Scrum',
  'Contract Negotiation',
  'Sales',
  'Marketing',
  'SEO',
]

const KeywordsCloud = () => {
  return (
    <div className="flex flex-wrap">
      {keywords.map((keyword) => (
        <div
          key={keyword}
          className="text-sm text-gray-600 bg-gray-100 rounded-full px-4 py-2 mr-2 mb-2 whitespace-nowrap"
        >
          {keyword}
        </div>
      ))}
    </div>
  )
}

export interface Feature {
  title: string
  description: string
  visual: string | React.ReactNode
  color: string
}

const features: Feature[] = [
  {
    title: 'AI-Powered Resume Tailoring',
    description:
      'Instantly create resumes optimized for specific job descriptions. Bloom analyzes keywords and skills to match your qualifications perfectly.',
    visual: '/resume-tailoring.png',
    color: 'hsl(340 26% 85% / 1)',
  },
  {
    title: 'Automated Application Question Answers',
    description:
      'Save time and effort. Bloom generates intelligent answers to common application questions, ensuring you always put your best foot forward.',
    visual: '/auto-answers.png',
    color: 'hsl(132 51% 83% / 1)',
  },
  {
    title: 'Resume Enhancement and Optimization',
    description:
      'Improve your existing resume with AI-driven suggestions. Bloom identifies areas for improvement and helps you craft a compelling narrative.',
    visual: '/resume-enhance.png',
    color: '#f9f7f6',
  },
  {
    title: 'Keyword Optimization',
    description:
      'Get past the ATS systems. Bloom analyses job descriptions and integrates the needed keywords to get your resume seen.',
    visual: <KeywordsCloud />,
    color: 'hsl(205 23% 80% / 1)',
  },
]

const FeatureCard = ({
  feature,
  index,
  range,
  targetScale,
  progress,
}: {
  feature: Feature
  index: number
  range: [number, number]
  targetScale: number
  progress: MotionValue<number>
}) => {
  // const ref = useRef(null)
  // const { scrollYProgress } = useScroll({
  //   target: ref,
  //   // container: containerRef,
  //   offset: ['start end', 'start start'],
  // })
  // const scale = useTransform(scrollYProgress, [0, 0.8, 1], [0.9, 0.9, 1])

  const scale = useTransform(progress, range, [1, targetScale])

  return (
    <motion.div
      className="rounded-4xl bg-neutral-100 grid md:grid-cols-2 overflow-hidden sticky origin-top"
      style={{
        backgroundColor: feature.color,
        top: 90 + index * 24,
        scale,
      }}
    >
      <div className="p-16">
        <h3 className="text-3xl font-serif text-gray-700 mb-8">
          {feature.title}
        </h3>

        <p className="text-gray-600 mb-4 leading-7 tracking-wide">
          {feature.description}
        </p>
      </div>

      <div className="block aspect-square w-full rounded-tl-4xl -mb-24 mt-24">
        {typeof feature.visual === 'string' ? (
          <img src={feature.visual} alt={feature.title} />
        ) : (
          feature.visual
        )}
      </div>
    </motion.div>
  )
}

export default function Features() {
  const containerRef = useRef(null)
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end end'],
  })

  return (
    <section className="py-24">
      <div className="container mx-auto">
        <h2 className="text-4xl font-serif text-gray-800 mb-16 text-center">
          Unlock Your Career Potential with Bloom's Powerful Features
        </h2>

        <div
          ref={containerRef}
          className="relative space-y-16 max-w-screen-lg mx-auto"
        >
          {features.map((feature, i) => (
            <FeatureCard
              key={feature.title}
              feature={feature}
              index={i}
              range={[i / features.length, 1]}
              targetScale={1 - (features.length - i) * 0.05}
              progress={scrollYProgress}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
