import { z } from 'zod'

import { Database } from './database.types'
import { supabase } from './supabase'
import { InputSchema } from '@/schema/AiSuggestionsSchema'
import { DuplicateResumeSchema } from '@/schema/DuplicateResumeSchema'
import { Resume } from './schema/ResumeSchema'

export const listResumes = async (userId?: string) => {
  let query = supabase
    .from('resumes')
    .select('id, user_id, name, template, updated_at, created_at')
    .order('updated_at', { ascending: false })

  if (userId) {
    query = query.eq('user_id', userId)
  }

  const { data, error } = await query

  if (error) {
    throw new Error(`Failed to fetch resumes: ${error.message}`)
  }

  return data
}

export const fetchResume = async (id: string) => {
  const { data, error } = await supabase
    .from('resumes')
    .select('*')
    .eq('id', id)
    .single()

  if (!data || error) {
    throw new Error(`Failed to fetch resume: ${error?.message}`)
  }

  return data
}

export const createResume = async ({ resume }: { resume?: Resume } = {}) => {
  const { data, error } = await supabase
    .from('resumes')
    .insert({
      name: 'New Resume',
      template: 'Brussels',
      resume,
    })
    .select()
    .single()

  if (error) {
    throw new Error(`Failed to create resume: ${error.message}`)
  }

  return data
}

export const updateResume = async ({
  id,
  ...resume
}: Database['public']['Tables']['resumes']['Update'] & { id: string }) => {
  return await supabase.from('resumes').update(resume).eq('id', id)
}

export const deleteResume = async (resume: { id: string; user_id: string }) => {
  await Promise.all([
    supabase.from('resumes').delete().eq('id', resume.id),
    supabase.storage
      .from('resumes')
      .remove([`${resume.user_id}/${resume.id}.pdf`]),
  ])
}

export const duplicateResumeById = () => ({
  mutationKey: ['duplicate-resume'],
  mutationFn: async ({ resumeId }: z.infer<typeof DuplicateResumeSchema>) => {
    const response = await supabase.functions.invoke('duplicate-resume', {
      body: { resumeId },
      method: 'POST',
    })

    return response.data.data
  },
})

export const uploadResumeFile = async ({
  blob,
  resumeId,
  userId,
}: {
  blob: Blob
  resumeId: string
  userId: string
}) => {
  const { error } = await supabase.storage
    .from('resumes')
    .upload(`${userId}/${resumeId}.pdf`, blob, { upsert: true })

  if (error) {
    console.error('Error uploading file', error)
    throw new Error(`Failed to upload file: ${error.message}`)
  }
}

export const createSignedUrl = async (
  resume: {
    id: string
    user_id: string
  },
  download?: string | boolean,
) => {
  const { data, error } = await supabase.storage
    .from('resumes')
    .createSignedUrl(`${resume.user_id}/${resume.id}.pdf`, 3600, {
      download,
    })

  if (error) {
    throw new Error(error.message)
  }

  return data
}

export const getFilePublicUrl = (path: string) => {
  return supabase.storage.from('uploads').getPublicUrl(path).data.publicUrl
}

export const uploadGenericFile = async ({
  blob,
  name,
  userId,
}: {
  blob: Blob
  name: string
  userId: string
}) => {
  const { error, data } = await supabase.storage
    .from('uploads')
    .upload(`${userId}/${name}`, blob, { upsert: true })

  if (error) {
    console.error('Error uploading file', error)
    throw new Error(`Failed to upload file: ${error.message}`)
  }

  const urlResponse = supabase.storage.from('uploads').getPublicUrl(data.path)

  return { url: urlResponse.data.publicUrl, path: data.path }
}

export const listFiles = async (userId: string) => {
  const { data, error } = await supabase.storage
    .from('uploads')
    .list(`${userId}/`)

  if (error) {
    throw new Error(`Failed to list files: ${error.message}`)
  }

  return data
}

export const aiAnswerQuestion = () => ({
  mutationKey: ['ai', 'answer-question'],
  mutationFn: async (input: z.infer<typeof InputSchema>['input']) => {
    const response = await supabase.functions.invoke('ai-suggestions', {
      body: { suggestionType: 'answer-question', input },
      method: 'POST',
    })

    return response.data.response
  },
})

export const aiTailorResume = () => ({
  mutationKey: ['ai', 'tailor-resume'],
  mutationFn: async (input: z.infer<typeof InputSchema>['input']) => {
    const response = await supabase.functions.invoke('ai-suggestions', {
      body: { suggestionType: 'tailor-resume', input },
      method: 'POST',
    })

    return response.data.response
  },
})

export const aiFixGrammar = () => ({
  mutationKey: ['ai', 'fix-grammar'],
  mutationFn: async (input: z.infer<typeof InputSchema>['input']) => {
    const response = await supabase.functions.invoke('ai-suggestions', {
      body: { suggestionType: 'fix-grammar', input },
      method: 'POST',
    })

    return response.data.response
  },
})
