import { useState } from 'react';
import { useCallback } from 'react';
import { Document, pdfjs, Thumbnail } from 'react-pdf'
import { useResizeObserver } from '@wojtekmaj/react-hooks'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import SkeletonDocument from './SkeletonDocument';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString()

const maxWidth = 300

export default function PdfThumbnail({ file }: { file: File | string }) {
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, {}, onResize)

  return (
    <div ref={setContainerRef}>
      <Document file={file} loading={<SkeletonDocument loading />}>
        <Thumbnail
          pageNumber={1}
          width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
          loading={<SkeletonDocument loading />}
        />
      </Document>
    </div>
  )
}
