import { ArrowLeftIcon, InfoCircledIcon, PlusIcon } from '@radix-ui/react-icons'
import { useNavigate } from '@tanstack/react-router'
import { useState } from 'react'
import Dropzone from 'react-dropzone'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { importResumeFromLinkedIn } from './linkedin'
import { createResume } from '@/repository'
import { cn } from '@/lib/utils'
import { useIsMobile } from '@/hooks/use-mobile'
export default function ImportResumeDialog() {
  const [importType, setImportType] = useState<'linkedin' | undefined>()
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size={isMobile ? 'icon' : 'sm'} icon={PlusIcon}>
          {!isMobile && 'Create Resume'}
        </Button>
      </DialogTrigger>

      <DialogContent>
        {importType ? (
          <DialogHeader className="flex flex-row items-center gap-2 space-y-0">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                setImportType(undefined)
              }}
            >
              <ArrowLeftIcon />
            </Button>

            <DialogTitle className="flex items-center gap-2">
              Import from LinkedIn
            </DialogTitle>
          </DialogHeader>
        ) : (
          <DialogHeader>
            <DialogTitle>Create a new resume</DialogTitle>
          </DialogHeader>
        )}

        {!importType && (
          <div className="flex flex-col gap-4 items-stretch w-72 mx-auto mt-4">
            <Button
              variant="outline"
              size="lg"
              onClick={async () => {
                const resume = await createResume()
                navigate({
                  to: `/resumes/$resumeId`,
                  params: { resumeId: resume.id },
                })
              }}
            >
              Start from scratch
            </Button>

            <Button
              variant="outline"
              size="lg"
              onClick={() => setImportType('linkedin')}
            >
              <img src="/icons/linkedin.png" className="size-6 mr-4" />
              Import from LinkedIn
            </Button>
          </div>
        )}

        {importType === 'linkedin' && (
          <>
            <div className="text-sm text-gray-500 mb-4 bg-sky-50 border border-sky-200 rounded-md p-4">
              <div className="flex items-center gap-2">
                <div className="p-1 rounded-full bg-sky-100 flex items-center justify-center">
                  <InfoCircledIcon className="size-5 text-sky-500" />
                </div>

                <div>
                  First, you must download your complete LinkedIn data from the{' '}
                  <a
                    href="https://www.linkedin.com/mypreferences/d/download-my-data"
                    target="_blank"
                    className="text-primary underline"
                    rel="noopener noreferrer"
                  >
                    LinkedIn download my data page
                  </a>
                  , then upload the zip file here.
                </div>
              </div>
            </div>

            <Dropzone
              accept={{ 'application/zip': ['.zip'] }}
              onDrop={async (files) => {
                const file = files[0]
                if (!file) return

                try {
                  const resume = await importResumeFromLinkedIn(file)
                  const newResume = await createResume({
                    resume,
                  })

                  navigate({
                    to: `/resumes/$resumeId`,
                    params: { resumeId: newResume.id },
                  })
                } catch (error) {
                  console.error('Error importing resume:', error)
                }
              }}
            >
              {({
                getRootProps,
                getInputProps,
                isDragAccept,
                isDragReject,
              }) => (
                <section
                  className={cn(
                    'border-2 border-dashed border-primary/20 hover:border-primary rounded-md p-4 text-center bg-secondary transition-colors cursor-pointer',
                    {
                      'border-primary': isDragAccept,
                      'border-red-500': isDragReject,
                    },
                  )}
                >
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    Click or drop
                    <br />
                    your LinkedIn data export
                    <small className="block mt-2 text-xs text-gray-500">
                      .zip format
                    </small>
                  </div>
                </section>
              )}
            </Dropzone>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
