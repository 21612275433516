import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { TailorResumeSchema as FunctionSchema } from '@/schema/AiSuggestionsSchema'
import TextareaField from '@/components/form/TextareaField'
import InputField from '@/components/form/InputField'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useResume } from '@/context/resume-context'
import { aiTailorResume } from '@/repository'
import ResponsePane from './ResponsePane'

const TailorResumeSchema = FunctionSchema.omit({ resume: true })

const TailorResume: React.FC = () => {
  const { resume } = useResume()

  const form = useForm<z.infer<typeof TailorResumeSchema>>({
    defaultValues: {
      jobPosition: '',
    },
    resolver: zodResolver(TailorResumeSchema),
  })

  const mutation = useMutation(aiTailorResume())

  return (
    <div className="grid grid-cols-2 gap-4">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(async (data) => {
            await mutation.mutateAsync({ ...data, resume: resume.resume })
          })}
          className="space-y-4"
        >
          <InputField
            form={form}
            label="Job Position *"
            name="jobPosition"
            placeholder="Software Engineer"
          />

          <TextareaField
            form={form}
            label="Job Description"
            name="jobDescription"
            placeholder="This job requires you to have experience in..."
          />

          <div className="text-right">
            <Button
              type="submit"
              disabled={mutation.isPending}
              loading={mutation.isPending}
            >
              Create a new tailored resume
            </Button>
          </div>
        </form>
      </Form>

      <ResponsePane query={mutation}>
        {mutation.isSuccess && (
          <Button asChild>
            <Link
              to="/resumes/$resumeId"
              params={{ resumeId: mutation.data.data.id }}
            >
              Go to your new resume
            </Link>
          </Button>
        )}
      </ResponsePane>
    </div>
  )
}

export default TailorResume
