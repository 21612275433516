import { Session } from '@supabase/supabase-js'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'

export interface RouterContext {
  auth: Session | null
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <div className="min-h-screen bg-zinc-50">
      <Outlet />
    </div>
  ),
})
