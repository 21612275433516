import { ExitIcon, StarFilledIcon } from '@radix-ui/react-icons'
import { useCallback } from 'react'

import { Avatar, AvatarFallback } from './ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui/dropdown-menu'
import { supabase } from '@/supabase'
import { useSession } from '@/hooks/use-session'

const UserMenu: React.FC = () => {
  const { isAdmin, session } = useSession()

  const signOut = useCallback(async () => {
    await supabase.auth.signOut()
    window.location.reload()
  }, [])

  if (!session) {
    return null
  }

  const initials = session.user.email![0].toUpperCase()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative">
          <Avatar className="hover:shadow transition-shadow cursor-pointer ring-1 ring-zinc-200">
            <AvatarFallback>{initials}</AvatarFallback>
          </Avatar>
          {isAdmin && (
            <StarFilledIcon className="absolute z-10 top-0.5 right-0.5 w-3 h-3 text-amber-400" />
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem>{session.user.email}</DropdownMenuItem>

          <DropdownMenuItem
            className="cursor-pointer"
            onClick={() => {
              signOut()
            }}
          >
            <div className="flex items-center text-destructive">
              <ExitIcon className="mr-2" />
              Logout
            </div>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default UserMenu
