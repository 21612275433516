import { ArrowRightIcon, MagicWandIcon } from '@radix-ui/react-icons'

export default function Hero() {
  return (
    <section
      className="grid lg:grid-cols-2 rounded-t-4xl p-24 max-w-screen-xl mx-auto"
      style={{
        backgroundImage:
          'url(/hero-waves.svg), linear-gradient(to bottom, #f9f7f6 60%, transparent)',
        backgroundSize: 'cover',
      }}
    >
      <div>
        <div className="inline-flex items-center gap-4 text-gray-700 mb-8 border rounded-full px-4 py-2">
          <MagicWandIcon className="size-5 text-lime-700" /> AI Powered
        </div>

        <h1 className="text-5xl font-medium text-gray-700 mb-8 font-serif">
          Craft Resumes That Open Doors.
        </h1>

        <p className="text-lg text-gray-500 mb-8">
          Stop struggling with resume writing. Bloom analyzes job descriptions,
          generates tailored resumes, and answers application questions, all in
          minutes.
        </p>

        <div className="flex items-center gap-4">
          <button className="group flex items-center gap-2 bg-lime-700 hover:bg-lime-800 transition-colors text-white px-8 py-4 rounded-full">
            Use Bloom
            <ArrowRightIcon className="size-5 group-hover:translate-x-1 transition-transform duration-300" />
          </button>

          <span>Forever free!</span>
        </div>
      </div>

      <img
        src="/hero-image.jpg"
        alt="Bloom Interface"
        className="max-w-md mx-auto mt-8"
      />
    </section>
  )
}
