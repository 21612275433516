/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LandingImport } from './routes/landing'
import { Route as AccountImport } from './routes/account'
import { Route as IndexImport } from './routes/index'
import { Route as ResumesResumeIdImport } from './routes/resumes.$resumeId'

// Create Virtual Routes

const LoginLazyImport = createFileRoute('/login')()

// Create/Update Routes

const LoginLazyRoute = LoginLazyImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/login.lazy').then((d) => d.Route))

const LandingRoute = LandingImport.update({
  id: '/landing',
  path: '/landing',
  getParentRoute: () => rootRoute,
} as any)

const AccountRoute = AccountImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const ResumesResumeIdRoute = ResumesResumeIdImport.update({
  id: '/resumes/$resumeId',
  path: '/resumes/$resumeId',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/account': {
      id: '/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AccountImport
      parentRoute: typeof rootRoute
    }
    '/landing': {
      id: '/landing'
      path: '/landing'
      fullPath: '/landing'
      preLoaderRoute: typeof LandingImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginLazyImport
      parentRoute: typeof rootRoute
    }
    '/resumes/$resumeId': {
      id: '/resumes/$resumeId'
      path: '/resumes/$resumeId'
      fullPath: '/resumes/$resumeId'
      preLoaderRoute: typeof ResumesResumeIdImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/account': typeof AccountRoute
  '/landing': typeof LandingRoute
  '/login': typeof LoginLazyRoute
  '/resumes/$resumeId': typeof ResumesResumeIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/account': typeof AccountRoute
  '/landing': typeof LandingRoute
  '/login': typeof LoginLazyRoute
  '/resumes/$resumeId': typeof ResumesResumeIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/account': typeof AccountRoute
  '/landing': typeof LandingRoute
  '/login': typeof LoginLazyRoute
  '/resumes/$resumeId': typeof ResumesResumeIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths: '/' | '/account' | '/landing' | '/login' | '/resumes/$resumeId'
  fileRoutesByTo: FileRoutesByTo
  to: '/' | '/account' | '/landing' | '/login' | '/resumes/$resumeId'
  id:
    | '__root__'
    | '/'
    | '/account'
    | '/landing'
    | '/login'
    | '/resumes/$resumeId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AccountRoute: typeof AccountRoute
  LandingRoute: typeof LandingRoute
  LoginLazyRoute: typeof LoginLazyRoute
  ResumesResumeIdRoute: typeof ResumesResumeIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AccountRoute: AccountRoute,
  LandingRoute: LandingRoute,
  LoginLazyRoute: LoginLazyRoute,
  ResumesResumeIdRoute: ResumesResumeIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/account",
        "/landing",
        "/login",
        "/resumes/$resumeId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/account": {
      "filePath": "account.tsx"
    },
    "/landing": {
      "filePath": "landing.tsx"
    },
    "/login": {
      "filePath": "login.lazy.tsx"
    },
    "/resumes/$resumeId": {
      "filePath": "resumes.$resumeId.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
