import { Link } from '@tanstack/react-router'
import { useQuery } from '@tanstack/react-query'
import { PersonIcon } from '@radix-ui/react-icons'
import dayjs from 'dayjs'
import React from 'react'

import { ResumeTableRow } from '@/types'
import { useSession } from '@/hooks/use-session'
import ResumeMenu from '@/components/ResumeMenu'
import { createSignedUrl } from '@/repository'
import PdfThumbnail from '@/components/pdf/PdfThumbnail'
import SkeletonDocument from '@/components/pdf/SkeletonDocument'

interface Props {
  resume: Omit<ResumeTableRow, 'resume' | 'theme'>
}

const getUpdatedAt = (date: string) => {
  const now = new Date()
  const diffInDays = dayjs(now).diff(dayjs(date), 'day')

  if (diffInDays < 90) return dayjs(date).fromNow()
  return dayjs(date).format('[on] d MMM YYYY')
}

const ResumeCard: React.FC<Props> = ({ resume }) => {
  const { session } = useSession()

  const pdfQuery = useQuery({
    queryKey: ['download-pdf', resume.id],
    queryFn: () => createSignedUrl(resume),
    retry(failureCount, error) {
      return error.message !== 'Object not found' && failureCount < 3
    },
  })

  const pdfUrl = pdfQuery.data?.signedUrl

  return (
    <Link
      to="/resumes/$resumeId"
      params={{ resumeId: resume.id }}
      className="group flex flex-col rounded-lg border overflow-hidden bg-white aspect-[3/4] cursor-pointer hover:shadow-md transition-shadow"
    >
      <div className="relative overflow-hidden flex-grow bg-gradient-to-br from-indigo-400 to-cyan-400">
        <div className="w-3/4 h-full mt-6 bg-white mx-auto overflow-hidden">
          {pdfQuery.isLoading || pdfQuery.isError || !pdfUrl ? (
            <SkeletonDocument loading={pdfQuery.isLoading} />
          ) : (
            <PdfThumbnail file={pdfUrl} />
          )}
        </div>

        <div
          className="absolute top-0 bottom-0 right-0 left-0 flex justify-end items-end p-2"
          style={{
            backgroundImage:
              'radial-gradient(100% 20px at 50% 100%, #b9b9b963, transparent)',
          }}
        >
          {session?.user.id !== resume.user_id && <PersonIcon />}
        </div>
      </div>

      <div className="flex">
        <div className="p-4 flex-grow">
          <div className="font-medium text-sm text-zinc-700 mb-0.5">
            {resume.name}
          </div>
          {resume.updated_at && (
            <div className="text-xs text-zinc-400">
              Updated {getUpdatedAt(resume.updated_at)}
            </div>
          )}
        </div>

        <div className="py-4 pr-2 opacity-0 group-hover:opacity-100 transition-opacity">
          <ResumeMenu resume={resume} />
        </div>
      </div>
    </Link>
  )
}

export default ResumeCard
