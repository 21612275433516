import {
  ArrowDownIcon,
  ArrowUpIcon,
  DragHandleHorizontalIcon,
  PlusIcon,
} from '@radix-ui/react-icons'
import {
  useFieldArray,
  type UseFieldArrayMove,
  type UseFieldArrayRemove,
  useFormContext,
  type UseFormReturn,
} from 'react-hook-form'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'

import InputField from '@/components/form/InputField'
import { Button } from '@/components/ui/button'
import type { Resume } from '@/schema/ResumeSchema'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import RemoveItemButton from '../RemoveItemButton'
import ImageField from '@/components/form/ImageField'
import ArrayField from '../ArrayField'
import NoTitleAccordion from '../NoTitleAccordion'
import TextareaField from '@/components/form/TextareaField'

interface HighlightProps {
  form: UseFormReturn<Resume>
  index: number
}

interface WorkExperienceFieldProps {
  id: string
  index: number
  isLast: boolean
  move: UseFieldArrayMove
  remove: UseFieldArrayRemove
}

const HighlightField = ({ index, form }: HighlightProps) => {
  const { fields, append, remove } = useFieldArray({
    name: `work.${index}.highlights`,
  })

  return (
    <div className="md:col-span-2">
      <header className="flex items-center justify-between mb-2">
        <h3>Highlights</h3>

        <Tooltip>
          <TooltipTrigger asChild>
            <Button size="icon" variant="ghost" onClick={() => append('')}>
              <PlusIcon />
            </Button>
          </TooltipTrigger>

          <TooltipContent>Add Highlight</TooltipContent>
        </Tooltip>
      </header>

      <div className="space-y-2">
        {fields.map((field, hIndex) => (
          <div key={field.id} className="group/highlight relative flex items-start gap-2">
            <TextareaField
              form={form}
              name={`work.${index}.highlights.${hIndex}`}
              className="flex-grow"
            />

            <RemoveItemButton
              onClick={() => remove(hIndex)}
              className="opacity-0 group-hover/highlight:opacity-100 transition-opacity"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const WorkExperienceField = ({
  index,
  isLast,
  move,
  remove,
  id,
}: WorkExperienceFieldProps) => {
  const form = useFormContext<Resume>()
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const company = form.watch(`work.${index}.name`)
  const position = form.watch(`work.${index}.position`)
  const triggerTitle = [position, company]
    .filter((v) => v !== '')
    .join(' @ ') || <NoTitleAccordion>New Work Experience</NoTitleAccordion>

  return (
    <div ref={setNodeRef} style={style}>
      <AccordionItem value={`work.${index}`} className="border-none">
        <AccordionTrigger className="group gap-6 bg-white py-2 px-4 rounded-lg border">
          <header className="flex flex-grow items-center gap-2 font-bold">
            <DragHandleHorizontalIcon
              className="cursor-ns-resize"
              {...attributes}
              {...listeners}
            />

            <div className="flex-grow">{triggerTitle}</div>

            <div className="flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
              <Button
                size="icon"
                variant="ghost"
                onClick={(event) => {
                  event.stopPropagation()
                  move(index, index - 1)
                }}
                disabled={index === 0}
                icon={ArrowUpIcon}
              />
              <Button
                size="icon"
                variant="ghost"
                onClick={(event) => {
                  event.stopPropagation()
                  move(index, index + 1)
                }}
                disabled={isLast}
                icon={ArrowDownIcon}
              />
              <RemoveItemButton onClick={() => remove(index)} />
            </div>
          </header>
        </AccordionTrigger>

        <AccordionContent className="mt-4 mb-8 p-0">
          <div className="group relative grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputField
              form={form}
              name={`work.${index}.position`}
              label="Position"
              placeholder="Software Engineer"
            />

            <InputField
              form={form}
              name={`work.${index}.name`}
              label="Company"
            />

            <ImageField form={form} name={`work.${index}.logo`} label="Logo" />

            <InputField
              form={form}
              name={`work.${index}.url`}
              label="Website"
              placeholder="https://example.com"
              type="url"
            />

            <InputField
              form={form}
              name={`work.${index}.summary`}
              label="Summary"
              className="md:col-span-2"
            />

            <InputField
              form={form}
              name={`work.${index}.startDate`}
              label="Start Date"
              placeholder="Jan 2023"
            />

            <InputField
              form={form}
              name={`work.${index}.endDate`}
              label="End Date"
              placeholder="Feb 2024"
            />

            <InputField
              form={form}
              name={`work.${index}.location`}
              label="Location"
            />

            <HighlightField index={index} form={form} />
          </div>
        </AccordionContent>
      </AccordionItem>
    </div>
  )
}

export default function WorkSection() {
  const form = useFormContext<Resume>()
  const fieldArray = useFieldArray({
    control: form.control,
    name: 'work',
  })

  const { fields, prepend, remove, move } = fieldArray

  return (
    <ArrayField
      addLabel="Work Experience"
      fieldArray={fieldArray}
      onAddClick={() => {
        prepend({
          name: '',
          position: '',
          logo: '',
          url: '',
          highlights: [],
          startDate: '',
          endDate: '',
          location: '',
          summary: '',
        })
        return `work.${fields.length}`
      }}
    >
      {fields.map((field, index) => (
        <WorkExperienceField
          key={field.id}
          id={field.id}
          index={index}
          move={move}
          remove={remove}
          isLast={index === fields.length - 1}
        />
      ))}
    </ArrayField>
  )
}
