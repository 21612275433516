import { FieldValues, Path, UseFormReturn } from 'react-hook-form'

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'

type InputProps = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'form'
>

const TextareaField = <TFieldValues extends FieldValues = FieldValues>({
  form,
  className,
  ...props
}: InputProps & {
  label?: string
  description?: string
  name: Path<TFieldValues>
  form: UseFormReturn<TFieldValues>
}) => (
  <FormField
    control={form.control}
    name={props.name}
    render={({ field }) => (
      <FormItem className={className}>
        {props.label && <FormLabel>{props.label}</FormLabel>}
        <FormControl>
          <div className="relative">
            <Textarea {...props} {...field} />
          </div>
        </FormControl>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <FormMessage />
      </FormItem>
    )}
  />
)

export default TextareaField
