import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'

import '@/lib/sentry'
import '../styles/globals.css'
import '../styles/tweakpane.css'
import App from '@/App'
import { TooltipProvider } from '@/components/ui/tooltip'

// Register DayJS plugin
dayjs.extend(relativeTime)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RecoilRoot>
      <React.Suspense fallback={<div>Loading...</div>}>
        <QueryClientProvider client={queryClient}>
          <TooltipProvider delayDuration={0}>
            <App />
          </TooltipProvider>
        </QueryClientProvider>
      </React.Suspense>
    </RecoilRoot>
  </React.StrictMode>,
)
