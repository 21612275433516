export default function ProblemSolution() {
  return (
    <section className="py-16 bg-gray-50 text-center">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold text-gray-800 mb-8">
          The Frustrations of Modern Job Hunting
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          <div className="text-left">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">
              The Resume Black Hole
            </h3>
            <p className="text-lg text-gray-600 mb-4">
              Countless applications, yet no response? Your resume might be lost in the ATS abyss.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mb-4">
              Generic Resumes, Minimal Impact
            </h3>
            <p className="text-lg text-gray-600">
              One-size-fits-all resumes fail to highlight your unique skills and relevance to specific roles.
            </p>
          </div>
          <div className="text-left">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">
              Time-Consuming Applications
            </h3>
            <p className="text-lg text-gray-600 mb-4">
              Answering repetitive questions and tailoring your resume for each job is exhausting.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mb-4">
              Lack of Confidence
            </h3>
            <p className="text-lg text-gray-600">
              Uncertainty about your resume's effectiveness can hinder your job search success.
            </p>
          </div>
        </div>
        <div className="mt-10">
          <p className="text-lg text-gray-600">
            Bloom provides targeted solutions to these common challenges, empowering you to stand out.
          </p>
        </div>
      </div>
    </section>
  );
}