import { useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'

import ResumeCard from '@/components/resume/ResumeCard'
import { listResumes } from '@/repository'
import { checkAuthentication } from '@/lib/authentication'
import UserMenu from '@/components/UserMenu'
import { useSession } from '@/hooks/use-session'
import ImportResumeDialog from '@/import/ImportResumeDialog'

export const Route = createFileRoute('/')({
  component: Index,
  beforeLoad: checkAuthentication,
})

function Index() {
  const { session } = useSession()

  const query = useQuery({
    queryKey: ['resumes', session!.user.id],
    queryFn: ({ queryKey }) => listResumes(queryKey[1]),
  })

  if (query.isLoading || query.isFetching) {
    return <div>Loading...</div>
  }

  if (query.isError) {
    return <div>Error: {query.error.message}</div>
  }

  const resumes = query.data

  return (
    <>
      <nav className="mb-12">
        <div className="container flex gap-8 pt-3 items-center">
          <img src="/logo.png" alt="Bloom Logo" className="h-10" />
          <span className="text-zinc-500 -ml-2 tracking-tight">BLOOM</span>

          <div className="flex-grow" />

          <ImportResumeDialog />

          <UserMenu />
        </div>
      </nav>

      <div className="container pb-12">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {resumes!.map((resume) => (
            <ResumeCard key={resume.id} resume={resume} />
          ))}
        </div>

        {resumes!.length === 0 && (
          <div className="text-center text-zinc-600">
            <div>
              You don't have any Resume yet!
              <br />
              Create your first one to get started.
            </div>
          </div>
        )}
      </div>
    </>
  )
}
