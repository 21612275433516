import { PlusIcon } from '@radix-ui/react-icons'
import { type PropsWithChildren, useState } from 'react'
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import type { FieldValues, UseFieldArrayReturn } from 'react-hook-form'

import { Accordion } from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'

interface Props<T extends FieldValues> extends PropsWithChildren {
  addLabel: string
  onAddClick: () => string
  fieldArray: UseFieldArrayReturn<T>
}

export default function ArrayField<T extends FieldValues>({
  addLabel,
  children,
  onAddClick,
  fieldArray,
}: Props<T>) {
  const [accordionState, setAccordionState] = useState<string[]>([])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (active.id !== over?.id && over?.id) {
      const oldIndex = fieldArray.fields.findIndex(
        (field) => field.id === active.id,
      )
      const newIndex = fieldArray.fields.findIndex(
        (field) => field.id === over.id,
      )

      fieldArray.move(oldIndex, newIndex)
    }
  }

  return (
    <section>
      <header className="flex items-center justify-end mb-4">
        <Button
          variant="outline"
          icon={PlusIcon}
          onClick={() => {
            const accordionItemId = onAddClick()
            setTimeout(
              () => setAccordionState([...accordionState, accordionItemId]),
              0,
            )
          }}
        >
          Add {addLabel}
        </Button>
      </header>

      <Accordion
        type="multiple"
        className="space-y-4"
        value={accordionState}
        onValueChange={setAccordionState}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={fieldArray.fields}
            strategy={verticalListSortingStrategy}
          >
            {children}
          </SortableContext>
        </DndContext>
      </Accordion>
    </section>
  )
}
