export default function CallToAction() {
  return (
    <section className="my-12 text-center">
      <div className="container mx-auto bg-[#f9f7f6] rounded-4xl p-16">
        <h2 className="text-3xl font-serif font-semibold text-gray-800 mb-8">
          Ready to Transform Your Job Search?
        </h2>

        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded">
          Start Your Free Trial Today
        </button>
      </div>
    </section>
  )
}
