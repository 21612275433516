import { createFileRoute } from '@tanstack/react-router'
import { ReactLenis } from 'lenis/react'

import Hero from '@/components/landing/Hero'
import ProblemSolution from '@/components/landing/ProblemSolution'
import Features from '@/components/landing/Features'
import Testimonials from '@/components/landing/Testimonials'
import CallToAction from '@/components/landing/CallToAction'
import Footer from '@/components/landing/Footer'

export const Route = createFileRoute('/landing')({
  component: RouteComponent,
})

function RouteComponent() {
  return (
    <ReactLenis root>
      <div className="bg-[#eae8e1] p-12">
        <Hero />
        <ProblemSolution />
        <Features />
        <Testimonials />
        <CallToAction />
        <Footer />
      </div>
    </ReactLenis>
  )
}
