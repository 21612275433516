import {
  useFieldArray,
  type UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form'

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import type { Resume } from '@/schema/ResumeSchema'
import InputField from '@/components/form/InputField'
import RemoveItemButton from '@/components/resume-form/RemoveItemButton'
import ArrayField from '@/components/resume-form/ArrayField'
import NoTitleAccordion from '@/components/resume-form/NoTitleAccordion'

interface CertificateFieldProps {
  index: number
  remove: UseFieldArrayRemove
}

const CertificateField = ({ index, remove }: CertificateFieldProps) => {
  const form = useFormContext<Resume>()

  const triggerTitle = [
    form.watch(`certificates.${index}.issuer`),
    form.watch(`certificates.${index}.name`),
  ]
    .filter((v) => v !== '')
    .join(' - ') || <NoTitleAccordion>New Certificate</NoTitleAccordion>

  return (
    <AccordionItem value={`certificates.${index}`} className="border-none">
      <AccordionTrigger className="group gap-6 bg-white py-2 px-4 rounded-lg border">
        <header className="flex flex-grow items-center justify-between gap-2 font-bold">
          {triggerTitle}
          <RemoveItemButton
            onClick={() => remove(index)}
            className="opacity-0 group-hover:opacity-100 transition-opacity"
          />
        </header>
      </AccordionTrigger>

      <AccordionContent className="mt-4 mb-8 p-0">
        <div className="group relative grid grid-cols-1 md:grid-cols-2 gap-2">
          <InputField
            form={form}
            name={`certificates.${index}.issuer`}
            label="Issuer"
          />

          <InputField
            form={form}
            name={`certificates.${index}.name`}
            label="Name"
          />

          <InputField
            form={form}
            name={`certificates.${index}.url`}
            label="Website"
            className="md:col-span-2"
            type="url"
          />

          <InputField
            form={form}
            name={`certificates.${index}.date`}
            label="Date"
            className="md:col-span-2"
          />
        </div>
      </AccordionContent>
    </AccordionItem>
  )
}

export default function CertificatesSection() {
  const fieldArray = useFieldArray<Resume>({
    name: 'certificates',
  })

  const { fields, append, remove } = fieldArray

  return (
    <ArrayField
      addLabel="Certificate"
      fieldArray={fieldArray}
      onAddClick={() => {
        append({ name: '', issuer: '' })
        return `certificates.${fields.length}`
      }}
    >
      {fields.map((field, index) => (
        <CertificateField key={field.id} index={index} remove={remove} />
      ))}
    </ArrayField>
  )
}
